export const ROUTE_404 = '/404';
export const ROUTE_CREATE_POST = '/create-post';

export const ROUTE_POST = '/:postSlug';
export type RoutePostParams = { postSlug: string };

export const ROUTE_POST_ADVANCED_SLUG = '/:year/:month(/:day)/:postSlug(/)';
export type RoutePostAdvancedSlugParams = {
  year: string;
  month: string;
  day?: string;
  postSlug: string;
};

export const ROUTE_EDIT_POST = '/:postSlug/edit';
export type RouteEditPostParams = { postSlug: string };
export const ROUTE_LAST_POST = '/';

export const ROUTE_LOGIN = '/login';
export const ROUTE_ACCOUNT_SUSPENDED = '/account-suspended';

export const ROUTE_PREVIEW_POST = '/:postId/preview/:instance(=*)';
export type RoutePreviewPostParams = {
  postSlug: string;
  instance: string;
};
export const ROUTE_EDIT_COMMENT = '/:postSlug/edit-comment/:commentId';
export type RouteEditCommentParams = {
  postSlug: string;
  commentId: string;
};
export const ROUTE_EDIT_COMMENT_ADVANCED_SLUG =
  '/:year/:month(/:day)/:postSlug/edit-comment/:commentId';
export type RouteEditCommentAdvancedSlugParams = {
  year: string;
  month: string;
  day?: string;
  postSlug: string;
  commentId: string;
};
