import React, { Component } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import withFontClassName from '../../hoc/with-font-class-name';
import scrollParent from '../../services/scroll-parent';
import Button from '../button';
import MoreIcon from '../icons/more-icon';
import styles from './dropdown-button.scss';

const POSITION_RIGHT = 'right';
const POSITION_CENTER = 'center';

const ICON_RIGHT_OFFSET = 22;

class DropdownButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: props.position || POSITION_CENTER,
      isMenuVisible: false,
      top: false,
    };
  }

  component = null;

  setActionsContainer = (node) => {
    this.actionsContainer = node;
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.hideComponent);
  }

  reposition = () => {
    if (!this.actionsContainer) {
      return;
    }

    const { position } = this.state;
    const trigger = findDOMNode(this).getBoundingClientRect();
    const actionsRect = findDOMNode(
      this.actionsContainer,
    ).getBoundingClientRect();
    const scrollParentRect = scrollParent().getBoundingClientRect();
    const positionOffset = ICON_RIGHT_OFFSET - Math.ceil(trigger.width / 2);

    if (scrollParentRect.bottom - actionsRect.bottom < 0 || this.props.top) {
      this.setState({
        top: true,
      });
    }

    if (position === POSITION_CENTER && actionsRect.right > window.innerWidth) {
      this.setState({
        position: POSITION_RIGHT,
        positionOffset,
      });
    }

    if (position === POSITION_RIGHT) {
      this.setState({
        positionOffset,
      });
    }
  };

  handleClick = (event) => {
    event.preventDefault();
    if (this.props.isDisabled) {
      return;
    }
    if (this.state.isVisible) {
      this.hideComponent();
    } else {
      this.showComponent();
    }
  };

  async showComponent() {
    this.component = await this.props.children();

    document.addEventListener('click', this.hideComponent);
    this.setState(
      {
        isMenuVisible: true,
      },
      this.reposition,
    );
  }

  hideComponent = () => {
    document.removeEventListener('click', this.hideComponent);
    this.setState({
      isMenuVisible: false,
    });
  };

  renderMenu() {
    const { contentFontClassName, actionsContainerClassName } = this.props;
    const { isMenuVisible, position, positionOffset, top } = this.state;

    const actionsClassName = classNames(
      actionsContainerClassName,
      styles.actionsContainer,
      contentFontClassName,
      'blog-text-color',
      'blog-card-border-color',
      {
        [styles.top]: top,
      },
    );
    const triangleClassName = classNames(
      styles.triangle,
      'blog-card-border-color',
      'blog-card-background-color',
    );

    const actionsStyle = {};
    if (position === POSITION_RIGHT) {
      actionsStyle.transform = `translateX(${positionOffset}px)`;
    }

    if (isMenuVisible) {
      return (
        <div
          className={actionsClassName}
          style={actionsStyle}
          ref={(node) => this.setActionsContainer(node)}
        >
          <div
            className={classNames(
              triangleClassName,
              'blog-card-background-color',
            )}
          />
          <div className="blog-card-background-color">{this.component}</div>
        </div>
      );
    }
  }

  render() {
    const { icon, buttonProps } = this.props;
    const { position } = this.state;
    const useFixedColor = get(buttonProps, 'useFixedColor');

    const moreButton = (
      <Button
        className={classNames(styles.button, 'profile-actions-button')}
        {...buttonProps}
      >
        <MoreIcon
          className={
            useFixedColor ? styles.fixedColor : 'blog-button-primary-icon-fill'
          }
        />
      </Button>
    );
    return (
      <span
        data-hook="dropdown-button"
        role="button"
        className={classNames(styles.container, styles[position])}
        onClick={this.handleClick}
      >
        {icon ? icon : moreButton}
        {this.renderMenu()}
      </span>
    );
  }
}

DropdownButton.propTypes = {
  buttonProps: PropTypes.object,
  children: PropTypes.func,
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  position: PropTypes.string,
  actionsContainerClassName: PropTypes.string,
  contentFontClassName: PropTypes.string.isRequired,
  top: PropTypes.bool,
};

DropdownButton.defaultProps = {
  buttonProps: {},
};

DropdownButton.POSITION_RIGHT = POSITION_RIGHT;

export default withFontClassName(DropdownButton);
